export default class ToolbarComponent {
    constructor() {
        ToolbarComponent.langs_switcher()
        ToolbarComponent.burger_menu_initializer()
    }

    static langs_switcher() {
        const list_langs = $('.lang__list')

        //Open submenu
        $('.tools__item__languages').on('click', () => {
            $(list_langs).toggleClass('lang__list--open')
            $('.lang__list--open').fadeIn(250)
            $('.lang__list:not(.lang__list--open)').fadeOut(250)
        })

        $('.lang__list > a').on('click', (e) => {
            updateCurrentLanguageTopbar()
        })

        const updateCurrentLanguageTopbar = () => {
            window.setTimeout(() => {
                let current_language = $('html').attr('lang').replace(/-.*/gi, '')

                current_language = current_language === 'auto' ? 'fr' : current_language

                $('.lang__current').text(current_language.toUpperCase())

                $('.glink').each(function () {
                    if ($(this).text() === current_language.toUpperCase()) {
                        $(this).css('display', 'none')
                    } else {
                        $(this).css('display', 'block')
                    }
                })
            }, 500)
        }

        $(window).on('load', () => {
            setTimeout(() => {
                updateCurrentLanguageTopbar()
            }, 500)
        })
    }

    static burger_menu_initializer() {
        const more_menu = '[data-more-menu]'
        const burger_menu = '[data-burger-menu]'

        $(document).on('click', burger_menu, (e) => {
            $('body').toggleClass('menu-is-open')
        })

        $(document).on('click', more_menu, function (e) {
            $(this).parent().toggleClass('submenu-is-open')
        })
    }
}
