export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky_navbar()
    }

    static sticky_navbar() {
        let last_scroll = 0
        let direction = null
        const trigger_height = 90

        // On scroll
        window.addEventListener('scroll', () => {
            const scroll = window.pageYOffset

            // Determines which direction
            if (scroll > last_scroll) {
                direction = 'bottom'
                last_scroll = scroll
            } else {
                direction = 'top'
                last_scroll = scroll
            }

            // Sets sticky when scroll up
            if (direction === 'bottom' && scroll > trigger_height) {
                document.body.classList.remove('navbar-is-sticky')
                document.body.classList.add('navbar-is-hidden')
            } else {
                document.body.classList.add('navbar-is-sticky')
                document.body.classList.remove('navbar-is-hidden')
            }

            // Removes class if scroll top
            if (scroll === '0') {
                document.body.classList.remove('navbar-is-sticky')
            }
        })
    }
}
